<template>
  <b-row>
    <b-col cols="12">
      <h5>
        <strong>{{ $t('clubs.intellectualProperty.formAgreements.agreementRegistration') }}</strong>
      </h5>
      <span class="i-text-request-info">
        {{ $t('corporate.create.textRequest') }}
      </span>
      <hr>
    </b-col>
    <b-col md="12">
      <b-overlay
        :show="applyOverlay"
        :class="applyOverlay ? 'p-2 mb-1' : 'mb-1'"
        opacity="1"
      >
        <template #overlay>
          <div class="d-flex align-items-center justify-content-center mt-1">
            <b-spinner
              type="border"
              variant="primary"
            />
          </div>
          <div class="d-flex justify-content-center">
            <p class="pt-1">
              {{ $t('loading') }}
            </p>
          </div>
        </template>
        <b-row>
          <b-col
            v-if="!applyOverlay"
            md="12"
          >
            <validation-observer ref="agreementRules">
              <b-form @submit.prevent="saveAgreementForm">
                <b-row>
                  <b-col md="6">
                    <b-form-group label-for="ag-assignor">
                      {{ $t('clubs.intellectualProperty.formAgreements.labels.assignor') }}
                      <validation-provider
                        #default="{ errors }"
                        name="assignor"
                        rules="min:1|max:255|nameRegex"
                      >
                        <b-form-input
                          id="ag-assignor"
                          v-model="agreementForm.assignorName"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="$t('clubs.intellectualProperty.formAgreements.placeholder.enterAssignor')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label-for="ag-personality">
                      {{ $t('clubs.intellectualProperty.formAgreements.labels.personality') }}
                      <validation-provider
                        #default="{ errors }"
                        name="personality"
                        rules="min:5|max:255|clubNameRegex"
                      >
                        <b-form-textarea
                          id="ag-personality"
                          v-model="agreementForm.personality"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('clubs.intellectualProperty.formAgreements.placeholder.enterPersonality')"
                          rows="1"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    sm="12"
                    md="12"
                  >
                    <b-form-group label-for="ag-address">
                      {{ $t('clubs.intellectualProperty.formAgreements.labels.addressForNotifications') }}
                      <validation-provider
                        #default="{ errors }"
                        name="addressForNotifications"
                        rules="addressRegex|min:10|max:255"
                      >
                        <b-form-input
                          id="ag-address"
                          v-model="agreementForm.address.address"
                          :state="errors.length > 0 ? false : null"
                          name="address"
                          :placeholder="$t('clubs.intellectualProperty.formAgreements.placeholder.enterAddressForNotifications')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="ag-zip-code">
                      {{ $t('accountSettings.zipCode') }}
                      <validation-provider
                        #default="{ errors }"
                        name="postalCode"
                        rules="zipRegex|min:2|max:5"
                      >
                        <b-form-input
                          id="ag-zip-code"
                          v-model="agreementForm.address.zipCode"
                          :state="errors.length > 0 ? false : null"
                          name="postal-code"
                          :placeholder="$t('accountSettings.placeholder.enterZipCode')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="ag-countries">
                      {{ $t('generic.country') }}
                      <validation-provider
                        #default="{ errors }"
                        name="country"
                      >
                        <v-select
                          id="ag-countries"
                          v-model="agreementForm.address.countryId"
                          :state="errors.length > 0 ? false : null"
                          label="name"
                          :reduce="val => val.id"
                          :options="countries"
                          :placeholder="$t('generic.selectCountry')"
                          @input="fetchStates(agreementForm.address.countryId)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="ag-states">
                      {{ $t('generic.state') }}
                      <validation-provider
                        #default="{ errors }"
                        name="state"
                      >
                        <v-select
                          id="ag-states"
                          v-model="agreementForm.address.stateId"
                          :state="errors.length > 0 ? false : null"
                          label="name"
                          :reduce="val => val.id"
                          :options="states"
                          :placeholder="$t('generic.selectState')"
                          :disabled="agreementForm.address.countryId == null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label-for="ag-email">
                      {{ $t('generic.email') }}
                      <validation-provider
                        #default="{ errors }"
                        name="email"
                        vid="email"
                        rules="email"
                      >
                        <b-form-input
                          id="ag-email"
                          v-model="agreementForm.email"
                          :state="errors.length > 0 ? false : null"
                          name="login-email"
                          placeholder="john@ostrail.com"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="ag-alternate-email">
                      {{ $t('clubs.intellectualProperty.formAgreements.labels.alternateEmail') }}
                      <validation-provider
                        #default="{ errors }"
                        name="alternateEmail"
                        vid="email"
                        rules="email"
                      >
                        <b-form-input
                          id="ag-alternate-email"
                          v-model="agreementForm.alternateEmail"
                          :state="errors.length > 0 ? false : null"
                          name="alternate-email"
                          placeholder="john@ostrail.com"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label-for="ag-phone">
                      {{ $t('accountSettings.phone') }}
                      <validation-provider
                        #default="{ errors }"
                        name="phone"
                        rules="min:10"
                      >
                        <cleave
                          id="ag-phone"
                          v-model="agreementForm.phone"
                          class="form-control"
                          :raw="false"
                          :state="errors.length > 0 ? false : null"
                          :options="clevePhone"
                          :placeholder="$t('accountSettings.placeholder.enterPhone')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="ag-alternate-phone">
                      {{ $t('clubs.intellectualProperty.formAgreements.labels.alternatePhone') }}
                      <validation-provider
                        #default="{ errors }"
                        name="alternatePhone"
                        rules="min:10"
                      >
                        <cleave
                          id="ag-alternate-phone"
                          v-model="agreementForm.alternatePhone"
                          class="form-control"
                          :raw="false"
                          :state="errors.length > 0 ? false : null"
                          :options="clevePhone"
                          :placeholder="$t('clubs.intellectualProperty.formAgreements.placeholder.enterAlternatePhone')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label-for="ag-images">
                      {{ $t('clubs.intellectualProperty.formTrademarks.labels.registerNumber') }}
                      <b-col
                        v-if="numbers.length == 0"
                        cols="12"
                        class="i-text-nrf"
                      >
                        <span class="text-danger">
                          {{ $t('generic.noRecordsFound') }}
                        </span>
                      </b-col>
                      <b-row
                        v-else
                        class="i-images i-margin-images"
                      >
                        <b-col
                          v-for="number in numbers"
                          :key="number.id"
                          sm="6"
                          md="6"
                          lg="4"
                        >
                          <b-card
                            no-body
                            class="i-shadow-cards"
                          >
                            <b-card-body class="justify-content-between align-items-center p-1">
                              <b-row>
                                <b-col
                                  cols="12"
                                  sm="12"
                                  md="5"
                                  lg="4"
                                  class="text-center mb-1"
                                >
                                  <template v-if="number.type == 'trademark'">
                                    <b-avatar
                                      class="cursor-pointer"
                                      size="75px"
                                      rounded="sm"
                                      :src="number.image"
                                    />
                                  </template>
                                  <template v-else>
                                    <b-img
                                      ref="btnShow"
                                      class="cursor-pointer"
                                      height="75"
                                      width="65"
                                      :src="number.contract"
                                    />
                                  </template>
                                </b-col>
                                <b-col
                                  sm="12"
                                  md="7"
                                  lg="8"
                                  class="text-center text-sm-center text-md-left mt-sm-1 mt-md-0"
                                >
                                  <template v-if="number.type == 'trademark'">
                                    <span class="font-weight-bolder">
                                      {{ $t('clubs.intellectualProperty.type') }}:
                                    </span>
                                    {{ $t('clubs.intellectualProperty.trademarkRegistration') }}
                                    <br>
                                    <span class="font-weight-bolder">
                                      {{ $t('clubs.intellectualProperty.formTrademarks.labels.registerNumber') }}:
                                    </span>{{ number.number }}
                                    <br>
                                    <span class="font-weight-bolder">
                                      {{ $t('clubs.intellectualProperty.formTrademarks.labels.denomination') }}:
                                    </span>
                                    {{ number.denomination }}
                                    <p style="margin-top: 4px">
                                      <b-form-checkbox
                                        v-model="number.assigned"
                                        class="i-display-ib"
                                        :value="!false"
                                      >
                                        {{ $t('clubs.intellectualProperty.formAgreements.labels.assigned') }}
                                      </b-form-checkbox>
                                    </p>
                                  </template>
                                  <template v-else>
                                    <span class="font-weight-bolder">
                                      {{ $t('clubs.intellectualProperty.type') }}:
                                    </span>
                                    {{ $t('clubs.intellectualProperty.literaryOrArtisticWorkRegistration') }}
                                    <br>
                                    <span class="font-weight-bolder">
                                      {{ $t('clubs.intellectualProperty.title') }}:
                                    </span>{{ number.title }}
                                    <p style="margin-top: 4px">
                                      <b-form-checkbox
                                        v-model="number.assigned"
                                        class="i-display-ib"
                                        :value="!false"
                                      >
                                        {{ $t('clubs.intellectualProperty.formAgreements.labels.assigned') }}
                                      </b-form-checkbox>
                                    </p>
                                  </template>
                                </b-col>
                              </b-row>
                            </b-card-body>
                          </b-card>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label-for="ag-dossier-number">
                      {{ $t('clubs.intellectualProperty.formAgreements.labels.dossierNumber') }}
                      <validation-provider
                        #default="{ errors }"
                        name="dossierNumber"
                        rules="integer|min:1|max:20"
                      >
                        <b-input-group>
                          <b-form-input
                            id="ag-dossier-number"
                            v-model="agreementForm.dossierNumber"
                            class="form-control"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('clubs.intellectualProperty.formAgreements.placeholder.enterDossierNumber')"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="ag-percentage">
                      {{ $t('clubs.intellectualProperty.formAgreements.labels.percentageOfAssignmentOfRights') }}
                      <validation-provider
                        #default="{ errors }"
                        name="percentageOfAssignmentOfRights"
                        rules="integer"
                      >
                        <b-input-group>
                          <b-form-input
                            id="ag-percentage"
                            v-model="agreementForm.percentage"
                            class="form-control"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('clubs.intellectualProperty.formAgreements.placeholder.enterPercentageOfAssignmentOfRights')"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="ag-cost">
                      {{ $t('clubs.intellectualProperty.formAgreements.labels.costOfAssignmentOfRights') }}
                      <validation-provider
                        #default="{ errors }"
                        name="costOfAssignmentOfRights"
                        rules="integer|min:1|max:20"
                      >
                        <b-input-group
                          prepend="$"
                          class="input-group-merge"
                        >
                          <b-form-input
                            id="ag-cost"
                            v-model="agreementForm.cost"
                            class="form-control"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('clubs.intellectualProperty.formAgreements.placeholder.enterCostOfAssignmentOfRights')"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group for="ag-date">
                      {{ $t('clubs.intellectualProperty.formAgreements.labels.dateOfAssignmentOfRights') }}
                      <validation-provider
                        #default="{ errors }"
                        name="dateOfAssignmentOfRights"
                      >
                        <b-form-datepicker
                          id="ag-date"
                          v-model="agreementForm.date"
                          hide-header
                          :locale="$i18n.locale"
                          :placeholder="$t('clubs.intellectualProperty.formAgreements.placeholder.enterDateOfAssignmentOfRights')"
                          :state="errors.length > 0 ? false:null"
                          :max="todayAg"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label-for="ag-payment-conditions">
                      {{ $t('clubs.intellectualProperty.formAgreements.labels.paymentConditions') }}
                      <validation-provider
                        #default="{ errors }"
                        name="paymentConditions"
                        rules="min:5|max:255|clubNameRegex"
                      >
                        <b-form-textarea
                          id="ag-payment-conditions"
                          v-model="agreementForm.paymentConditions"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('clubs.intellectualProperty.formAgreements.placeholder.enterPaymentConditions')"
                          rows="1"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label-for="ag-observations">
                      {{ $t('clubs.intellectualProperty.formAgreements.labels.otherBenefitsOrObservations') }}
                      <validation-provider
                        #default="{ errors }"
                        name="otherBenefitsOrObservations"
                        rules="min:5|max:255|clubNameRegex"
                      >
                        <b-form-textarea
                          id="ag-observations"
                          v-model="agreementForm.otherBenefitsOrObservations"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('clubs.intellectualProperty.formAgreements.placeholder.enterOtherBenefitsOrObservations')"
                          rows="1"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <hr>
                    <b-button
                      class="float-right ml-1"
                      variant="primary"
                      pill
                      :style="colorPrimaryBtn"
                      type="submit"
                    >
                      {{ $t( 'buttons.save') }}
                    </b-button>
                    <b-button
                      class="float-right"
                      variant="secondary"
                      pill
                      @click="$emit('change-type-view-ag')"
                    >
                      {{ $t('buttons.cancel') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-col>
          <b-col
            v-else
            cols="12"
            class="i-height-div"
          />
        </b-row>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mapGetters } from 'vuex'
import { validatorFileSize, validatorFileType } from '@core/utils/validations/validators'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, integer, min, max, nameRegex, clubNameRegex, email } from '@validations'
import {
  BFormGroup, BForm, BFormInput, BFormDatepicker, BButton, VBTooltip, BAvatar, BCard, BCardBody, BFormCheckbox, BImg,
} from 'bootstrap-vue'
import agreementService from '@/services/agreementService'
import clubService from '@/services/clubsService'
import countryService from '@/services/countryService'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import 'vue-advanced-cropper/dist/style.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BForm,
    BFormInput,
    BButton,
    BFormDatepicker,
    vSelect,
    Cleave,
    BAvatar,
    BCard,
    BCardBody,
    BFormCheckbox,
    BImg,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  props: {
    actionsAg: {
      type: Object,
      default: () => {
      },
    },
  },

  data() {
    return {
      stateList: [],
      countriesList: [],
      applyOverlay: false,
      todayAg: new Date(),
      agreementForm: {
        id: null,
        clubId: null,
        assignorName: null,
        personality: null,
        address: {
          address: null,
          countryId: null,
          stateId: null,
          zipCode: null,
        },
        email: null,
        alternateEmail: null,
        phone: null,
        alternatePhone: null,
        registerNumbers: [],
        dossierNumber: null,
        percentage: null,
        date: null,
        cost: null,
        paymentConditions: null,
        otherBenefitsOrObservations: null,
      },
      filtersObj: {
        country_hash: null,
      },
      clevePhone: {
        phone: true,
        phoneRegionCode: 'MX',
      },
      /* validation */
      required,
      integer,
      min,
      max,
      nameRegex,
      clubNameRegex,
      email,
      numbers: [],
    }
  },

  computed: {
    ...mapGetters({ colorPrimaryBtn: 'colorPrimaryBtn' }),

    countries() {
      const list = this.countriesList.map(c => ({ id: c.hash, name: c.labelables[0].label }))
      return list
    },

    states() {
      const list = this.stateList.map(c => ({ id: c.hash, name: c.name }))
      return list
    },
  },

  watch: {
    '$i18n.locale': function () {
      this.fetchCountries()
    },

    'agreementForm.address.countryId': function (item, old) {
      if (item != null) {
        if (item != old && old != null && old !== '') {
          this.agreementForm.address.stateId = null
          this.statesList = []
        }
        this.fetchStates(item)
      } else {
        this.agreementForm.address.stateId = null
        this.statesList = []
      }
    },
  },

  mounted() {
    this.assignAgreement()
  },

  methods: {
    validatorFileSize,
    validatorFileType,

    fetchCountries() {
      countryService.getCountries().then(({ data }) => {
        this.countriesList = data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    fetchStates(id) {
      const filtersObj = { country_hash: id }
      countryService.getStates(filtersObj).then(({ data }) => {
        this.stateList = data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    fetchRegisterNumbers() {
      clubService.getRegisterNumbersByClubId(this.actionsAg.clubId).then(({ data }) => {
        this.numbers = data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    assignAgreement() {
      this.fetchCountries()
      this.fetchRegisterNumbers()

      if (this.actionsAg.name == 'edit') {
        this.applyOverlay = true

        agreementService.getAgreementInfo(this.actionsAg.agreementId).then(({ data }) => {
          this.agreementForm.id = data.data.hash
          this.agreementForm.clubId = data.data.club_hash
          this.agreementForm.assignorName = data.data.assignor_name
          this.agreementForm.personality = data.data.personality
          this.agreementForm.address.address = data.data.address != null ? data.data.address.address : null
          this.agreementForm.address.countryId = data.data.address != null ? data.data.address.country_hash : null
          this.agreementForm.address.stateId = data.data.address != null ? data.data.address.state_hash : null
          this.agreementForm.address.zipCode = data.data.address != null ? data.data.address.zip_code : null
          this.agreementForm.email = data.data.email
          this.agreementForm.alternateEmail = data.data.alternate_email
          this.agreementForm.phone = data.data.phone
          this.agreementForm.alternatePhone = data.data.alternate_phone
          this.agreementForm.registerNumbers = data.data.register_numbers
          this.agreementForm.dossierNumber = data.data.dossier_number
          this.agreementForm.percentage = data.data.percentage
          this.agreementForm.date = data.data.date
          this.agreementForm.cost = data.data.cost
          this.agreementForm.paymentConditions = data.data.payment_conditions
          this.agreementForm.otherBenefitsOrObservations = data.data.other_benefits_or_observations
          this.filterByAssigned()
          this.applyOverlay = false
        }).catch(error => {
          this.applyOverlay = false
          this.responseCatch(error)
        })
      }
    },

    filterByAssigned() {
      this.agreementForm.registerNumbers.forEach(item => {
        const index = this.numbers.findIndex(el => el.id === item)
        // eslint-disable-next-line prefer-const
        let back = this.numbers[index]
        back.assigned = true
        this.numbers.splice(index, 1, back)
      })
    },

    saveAgreementForm() {
      this.$refs.agreementRules.validate().then(success => {
        if (success) {
          const assignations = this.numbers.filter(e => e.assigned === true)
          const formData = {
            club_id: parseInt(this.actionsAg.clubId, 10),
            assignor_name: this.agreementForm.assignorName,
            personality: this.agreementForm.personality,
            address: this.agreementForm.address.address,
            country_id: this.agreementForm.address.countryId,
            state_id: this.agreementForm.address.stateId,
            zip_code: this.agreementForm.address.zipCode,
            email: this.agreementForm.email,
            alternate_email: this.agreementForm.alternateEmail,
            phone: this.agreementForm.phone,
            alternate_phone: this.agreementForm.alternatePhone,
            register_numbers: assignations,
            dossier_number: this.agreementForm.dossierNumber,
            percentage: this.agreementForm.percentage,
            date: this.agreementForm.date,
            cost: this.agreementForm.cost,
            payment_conditions: this.agreementForm.paymentConditions,
            other_benefits_or_observations: this.agreementForm.otherBenefitsOrObservations,
          }

          if (this.actionsAg.name == 'edit') {
            formData.id = this.agreementForm.id

            agreementService.updateAgreement(formData).then(({ data }) => {
              this.responseSuccessUpdate(data.message)
              this.$emit('change-type-view-ag')
            }).catch(error => {
              this.responseCatch(error)
            })
          } else {
            agreementService.storeAgreement(formData).then(({ data }) => {
              this.responseSuccessCreate(data.message)
              this.$emit('change-type-view-ag')
            }).catch(error => {
              this.responseCatch(error)
            })
          }
        }
      })
    },

    fileValidation(event) {
      if (event.target.file !== 'undefined') {
        const fileX = event.target.files[0]
        // const validSize = this.validatorFileSize(fileX)
        const validType = this.validatorFileType(fileX)

        // if (!validSize) {
        //   this.$refs['file-trademark-input'].reset()
        //   this.showError(`${this.$t('the-file-size-exceeds-the-allowed-size')}: 2MB`)
        // }

        if (!validType) {
          this.$refs['file-trademark-input'].reset()
          this.showError(`${this.$t('only-documents-in-PDF-and-image-format-are-allowed')}`)
        }
      } else this.$refs['file-trademark-input'].reset()
    },
  },
}
</script>

<style scoped>
.i-demo-img {
  border-radius: 0.358rem;
  width: 100%;
  object-fit: contain;
}

.cropper {
  height: 360px;
  background: #DDD;
}

.i-translate-avatar-cl {
  transform: translate(0px, 105px);
}

.i-display-elements {
  display: inline-block;
  position: relative;
}

.i-display-elements p{
  position: absolute;
  bottom: 100px;
  right: 23px;
}

.i-images {
  height: 300px;
  overflow: scroll;
  border: 1px solid #e2e2ec;
  border-radius: 6px;
  overflow-x: hidden;
}

.i-images {
  scrollbar-width: thin;
  scrollbar-color:  rgba(0, 0, 0, 0.15) #f6f8fb;
}

/* Scroll Chrome, Edge, and Safari */
.i-images::-webkit-scrollbar {
  height: 8px;
  width: 12px;
}

.i-images::-webkit-scrollbar-track {
  background: #f6f8fb;
}

.i-images::-webkit-scrollbar-thumb {
  background-color:  rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  border: 3px solid #f6f8fb;
}

.i-margin-images {
  margin: 2px 0 2px 0;
  padding-top: 20px;
}

.i-shadow-cards {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1) !important;
}

.i-text-nrf {
  height: 40px;
  border: 1px solid #e2e2ec;
  border-radius: 6px;
  padding-top: 8px;
}

.i-display-ib {
  display: inline-block;
}
</style>
