<template>
  <b-overlay
    :show="applyOverlayD"
    :class="applyOverlayD ? 'p-2 mb-1' : 'mb-1'"
  >
    <template #overlay>
      <div class="d-flex align-items-center justify-content-center mt-1">
        <b-spinner
          type="border"
          variant="primary"
        />
      </div>
      <div class="d-flex justify-content-center">
        <p class="pt-1">
          {{ $t('loading') }}
        </p>
      </div>
    </template>
    <b-row v-if="!applyOverlayD">
      <!-- Convenio -->
      <b-col md="12">
        <b-row>
          <b-col md="6">
            <h5 class="mt-1">
              <strong>{{ $t('clubs.intellectualProperty.formAgreements.labels.agreementsData') }}</strong>
            </h5>
          </b-col>
          <b-col md="6">
            <span
              v-if="nAgreements > 1 && checkPermission(['DELETE_ASSEMBLY', 'ALLOW_ALL'])"
              class="float-right mt-1 ml-1"
            >
              {{ $t( 'buttons.delete') }}
              <b-button
                class="btn-icon rounded-circle"
                variant="danger"
                @click="$emit('delete-agreement', actionsAg.agreementId)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </span>
            <span
              v-if="checkPermission(['EDIT_ASSEMBLY', 'ALLOW_ALL'])"
              class="float-right mt-1"
            >
              {{ $t( 'buttons.edit') }}
              <b-button
                class="btn-icon rounded-circle"
                variant="secondary"
                @click="$emit('edit-agreement', actionsAg.agreementId)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </span>
          </b-col>
        </b-row>
        <hr style="margin-top: 8px;">
      </b-col>
      <b-col md="12">
        <b-list-group>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.intellectualProperty.formAgreements.labels.assignor') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ agreementInfoD.assignor_name }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.intellectualProperty.formAgreements.labels.personality') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ agreementInfoD.personality }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.intellectualProperty.formAgreements.labels.addressForNotifications') }}</strong>
              </b-col>
              <b-col
                v-if="agreementInfoD.address"
                md="6"
              >
                <span class="float-left">{{ agreementInfoD.address.address == null ? '' : agreementInfoD.address.address }}</span>
                <span>{{ agreementInfoD.address.address == null ? '' : ', ' + agreementInfoD.address.zip_code }}</span>
                <span>{{ agreementInfoD.address.state == null ? '' : ', ' + agreementInfoD.address.state.name }}</span>
                <span>{{ agreementInfoD.address.country == null ? '' : ', ' + agreementInfoD.address.country.labelables[0].label }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('generic.email') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ agreementInfoD.email }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.intellectualProperty.formAgreements.labels.alternateEmail') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ agreementInfoD.alternate_email }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('accountSettings.phone') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ agreementInfoD.phone }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.intellectualProperty.formAgreements.labels.alternatePhone') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ agreementInfoD.alternate_phone }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.intellectualProperty.formAgreements.labels.dossierNumber') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ agreementInfoD.dossier_number }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.intellectualProperty.formAgreements.labels.percentageOfAssignmentOfRights') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ agreementInfoD.percentage }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.intellectualProperty.formAgreements.labels.costOfAssignmentOfRights') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ agreementInfoD.cost }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.intellectualProperty.formAgreements.labels.dateOfAssignmentOfRights') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ agreementInfoD.date }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.intellectualProperty.formAgreements.labels.paymentConditions') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ agreementInfoD.payment_conditions }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.intellectualProperty.formAgreements.labels.otherBenefitsOrObservations') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ agreementInfoD.other_benefits_or_observations }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.intellectualProperty.formTrademarks.labels.representativeImageOfTheTrademark') }}</strong>
              </b-col>
              <b-col md="6">
                <span>. . .</span>
                <b-media
                  v-for="tradeM in agreementInfoD.trademarks"
                  :key="tradeM.hash"
                  class="float-right"
                  no-body
                >
                  <div
                    class="ml-2"
                    style="display: flex; margin-top: 3px;"
                  >
                    <div
                      v-if="checkPermission(['VIEW_CONTRACT_FILE_MEMBERS', 'ALLOW_ALL'])"
                      class="float-right"
                      style="display: flex;"
                    >
                      <div v-if="isObject(tradeM.image_media_file) && tradeM.image_media_file.mime_type != 'application/pdf'">
                        <image-viewer
                          class="cursor-pointer mr-1"
                          :document-id="tradeM.image_file_hash"
                          :url-thumb="tradeM.image_media_file.url_thumb"
                          :height="'30'"
                          :width="'30'"
                          :permission-show="'VIEW_CONTRACT_FILE_MEMBERS'"
                          :permission-download="'DOWNLOAD_CONTRACT_FILE_MEMBERS'"
                        />
                      </div>
                      <div v-else>
                        <ViewerDocumentModal
                          class="cursor-pointer mr-1"
                          :title="tradeM.image_media_file ? tradeM.image_media_file.title : ''"
                          :document-id="tradeM.image_file_hash"
                          :height="'30'"
                          :width="'30'"
                          :permission-show="'VIEW_CONTRACT_FILE_MEMBERS'"
                          :permission-download="'DOWNLOAD_CONTRACT_FILE_MEMBERS'"
                        />
                      </div>
                      <div v-if="tradeM.image_file_hash != null && checkPermission(['DOWNLOAD_CONTRACT_FILE_MEMBERS', 'ALLOW_ALL'])">
                        <span
                          class="float-right cursor-pointer"
                          @click="handleDownloadFile(tradeM.image_file_hash, tradeM.image_media_file.title)"
                        >
                          <i
                            class="icon-0-icons-dark-download btn-cursor"
                            style="font-size: 20px;"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </b-media>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.labels.contractFile') }}</strong>
              </b-col>
              <b-col md="6">
                <span>. . .</span>
                <b-media
                  v-for="loaw in agreementInfoD.literary_or_artistic_works"
                  :key="loaw.hash"
                  class="float-right"
                  no-body
                >
                  <div
                    class="ml-2"
                    style="display: flex; margin-top: 3px;"
                  >
                    <div
                      v-if="checkPermission(['VIEW_CONTRACT_FILE_MEMBERS', 'ALLOW_ALL'])"
                      class="float-right"
                      style="display: flex;"
                    >
                      <div v-if="isObject(loaw.contract_media_file) && loaw.contract_media_file.mime_type != 'application/pdf'">
                        <image-viewer
                          class="cursor-pointer mr-1"
                          :document-id="loaw.contract_file_hash"
                          :url-thumb="loaw.contract_media_file.url_thumb"
                          :height="'30'"
                          :width="'30'"
                          :permission-show="'VIEW_CONTRACT_FILE_MEMBERS'"
                          :permission-download="'DOWNLOAD_CONTRACT_FILE_MEMBERS'"
                        />
                      </div>
                      <div v-else>
                        <ViewerDocumentModal
                          class="cursor-pointer mr-1"
                          :title="loaw.contract_media_file ? loaw.contract_media_file.title : ''"
                          :document-id="loaw.contract_file_hash"
                          :height="'30'"
                          :width="'30'"
                          :permission-show="'VIEW_CONTRACT_FILE_MEMBERS'"
                          :permission-download="'DOWNLOAD_CONTRACT_FILE_MEMBERS'"
                        />
                      </div>
                      <div v-if="loaw.contract_file_hash != null && checkPermission(['DOWNLOAD_CONTRACT_FILE_MEMBERS', 'ALLOW_ALL'])">
                        <span
                          class="float-right cursor-pointer"
                          @click="handleDownloadFile(loaw.contract_file_hash, loaw.contract_media_file.title)"
                        >
                          <i
                            class="icon-0-icons-dark-download btn-cursor"
                            style="font-size: 20px;"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </b-media>
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <!--/ Convenio -->
      <login-modal
        :permission="validPermission"
        @change="toAccess"
      />
    </b-row>
    <b-row v-else>
      <b-col
        md="12"
        class="i-height-div"
      />
    </b-row>
  </b-overlay>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mapActions } from 'vuex'
import { isObject, downloadFile } from '@core/utils/utils'
import { BRow, BCol, BListGroup, BListGroupItem, BButton, BOverlay } from 'bootstrap-vue'
import agreementService from '@/services/agreementService'
import ImageViewer from '@/views/member/ImageViewer.vue'
import LoginModal from '@/views/member/LoginModal.vue'
import ViewerDocumentModal from '@/views/member/ViewerDocumentModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BButton,
    BOverlay,
    ImageViewer,
    ViewerDocumentModal,
    LoginModal,
  },

  props: {
    actionsAg: {
      type: Object,
      default: () => {},
    },
    nAgreements: {
      type: Number,
      default: null,
    },
    checkPermission: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      validPermission: null,
      agreementInfoD: {},
      applyOverlayD: false,
    }
  },

  created() {
    this.fetchAgreementInfo()
  },

  methods: {
    downloadFile,
    isObject,
    ...mapActions({ mediaFileShow: 'mediaFile/show' }),

    fetchAgreementInfo() {
      this.applyOverlayD = true

      agreementService.getAgreementInfo(this.actionsAg.agreementId).then(({ data }) => {
        this.agreementInfoD = data.data
        this.applyOverlayD = false
      }).catch(error => {
        this.applyOverlayD = false
        this.responseCatch(error)
      })
    },

    async toAccess(permissionData) {

    },

    async handleDownloadFile(fileHash, title) {
      this.isLoading = true
      await this.mediaFileShow(fileHash).then(response => {
        const { data: dataDocument } = response
        this.downloadFile(dataDocument, title)
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
        this.responseCatch(error)
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>

<style scoped>
.i-c-pointer:hover {
  color: #6abeff;
}
</style>